'use client'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

export default function ApntNavigation()
{
	const pathname = usePathname()
	const searchParams = useSearchParams()
	useEffect(() =>
	{
		if (window && (window as any)._apnt && (window as any)._apnt.binder)
		{
			(window as any)._apnt.binder.bindlinks();
		}
	}, [pathname, searchParams])
	return <></>;
}

