'use client';
import { useEffect } from 'react';

export default function JsLoad({ src, cb = false }: { src: string, cb: any | undefined | null })
{

	useEffect(() =>
	{

		const handleScriptLoad = (cb: any | undefined | null) =>
		{
			if (cb)
			{
				cb();
			}
			// console.log(`Script loaded: ${src}`);
			// You can perform any actions here that rely on the loaded script
		};

		const handleScriptError = () =>
		{
			// console.error(`Script failed to load: ${src}`);
			// You can handle the error here, e.g., by displaying an error message to the user
		};

		if (typeof window !== 'undefined')
		{
			// Set a global property on the window object
			window.__av_shop_id = parseInt(process.env.NEXT_PUBLIC_SHOP_ID ?? '0');
		}

		// Create a new script element
		const script = document.createElement('script');
		script.src = src;
		script.async = true; // You can set this to false if you want the script to load synchronously

		// Add an event listener to handle script load or error
		script.addEventListener('load', () => { handleScriptLoad(cb); });
		script.addEventListener('error', handleScriptError);

		// Append the script element to the document body
		document.body.appendChild(script);

		// Cleanup: Remove the script and event listeners when the component unmounts
		return () =>
		{
			script.removeEventListener('load', handleScriptLoad);
			script.removeEventListener('error', handleScriptError);
			document.body.removeChild(script);
		};
	}, [src, cb]);

	return <div></div>;
}

