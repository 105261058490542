'use client';
import { useEffect } from 'react';

export default function PreviewControl()
{
	useEffect(() =>
	{
		window.addEventListener('message', function (event: any)
		{
			console.log("Signal received from WPOW: " + event.data);
			if (event.data == 'reload')
			{
				document.location.reload();
			}
		});
		console.log('Setup preview control handlers... done');
	}, []);

	return <div></div>;
}
